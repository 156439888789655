import React, { memo } from 'react'
import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
} from '@chakra-ui/react'

interface IButton extends ChakraButtonProps {}

/**
 * The Button is a generic themed button using Chakra UI's Button
 * Its props extends Chakra UI's ButtonProps.
 * Chakra Reference: https://chakra-ui.com/docs/form/button
 * @prop showRightIconOnHover - Shows the right icon (with a transitional effect) only upon hovering the button
 * @returns the Button component
 */
const Button: React.FC<IButton> = ({ children, ...props }: IButton) => {
  let className = 'button'
  if (props.className) className += ` ${props.className}`

  return (
    <ChakraButton
      className={className}
      bg="primary.main"
      color="button.text"
      transition="background 0.3s ease-in"
      _hover={{ bg: 'primary.800' }}
      _active={{ bg: 'primary.800' }}
      _focus={{ bg: 'primary.800' }}
      {...props}
    >
      {children}
    </ChakraButton>
  )
}

export default memo(Button)
