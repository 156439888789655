export const fonts = {
  heading: `'Fifties', Cambria, Cochin, Georgia, Times, 'Times New Roman', serif`,
  body: `'Open Sans', 'Segoe UI', Tahoma, sans-serif`,
}

export const textStyles = {
  h1: {
    fontFamily: fonts.heading,
    fontSize: ['2.5rem', '2.5rem', '3rem'],
    fontWeight: 'bold',
    letterSpacing: '1px',
  },
  h2: {
    fontFamily: fonts.heading,
    fontSize: ['2rem', '2rem', '2.5rem'],
    fontWeight: 'semibold',
    letterSpacing: '1px',
  },
  h3: {
    fontFamily: fonts.heading,
    fontSize: ['1.5rem', '1.5rem', '2rem'],
    fontWeight: 'semibold',
    letterSpacing: '1px',
  },
  nav: {
    fontFamily: fonts.heading,
    fontSize: ['1.5rem', '1rem'],
    fontWeight: 'light',
    letterSpacing: '1px',
  },
  p: {
    fontFamily: fonts.body,
    fontWeight: 'light',
  },
}
