import { useState, useEffect, useRef } from 'react'
import { getTimeDifferences } from '@/utils/time-utils'

type TimeDifferences = {
  days: string | number
  hours: string | number
  mins: string | number
  secs: string | number
  isPastTargetDate: boolean
}

export default () => {
  const launchDayTimer = useRef(0)
  const targetDate = useRef(new Date(2022, 0, 15, 11, 0, 0))
  const [timeTilLaunch, setTimeTilLaunch] = useState<TimeDifferences>(
    getTimeDifferences(new Date(), targetDate.current)
  )

  const countdown = () => {
    if (global.nillysDev) {
      setTimeTilLaunch({ ...timeTilLaunch, isPastTargetDate: true })
      return
    }

    if (!timeTilLaunch.isPastTargetDate) {
      launchDayTimer.current = window.setInterval(() => {
        const timeDifferences = getTimeDifferences(
          new Date(),
          targetDate.current
        )

        if (timeDifferences.isPastTargetDate) {
          // reload page
          clearInterval(launchDayTimer.current)
        }

        setTimeTilLaunch(timeDifferences)
      }, 1000)
    }
  }

  useEffect(() => {
    if (global.nillysDev) {
      clearInterval(launchDayTimer.current)
      setTimeTilLaunch({ ...timeTilLaunch, isPastTargetDate: true })
    }
  }, [global.nillysDev])

  useEffect(() => {
    countdown()
    return () => {
      clearInterval(launchDayTimer.current)
    }
  }, [])

  return timeTilLaunch
}
