import { Box, Flex, useColorModeValue } from '@chakra-ui/react'
import React, { memo, useState } from 'react'
import BurgerMenu from '../BurgerMenu'
import Logo from '../Logo'
import NavBar from '../NavBar'

interface IHeader {
  showBoxShadow?: boolean
}

/**
 * The Header is the section of content at the very top of a web page.
 * This Header contains the business logo and navigation bar/menu (i.e the NavBar)
 * Reference: https://www.muse-themes.com/blogs/news/what-is-a-website-header
 * @returns the Header component
 */
const Header: React.FC<IHeader> = ({ showBoxShadow, ...props }: IHeader) => {
  const [isBurgerMenuOpen, setNavOpen] = useState(false)
  const onToggleBurgerMenu = () => setNavOpen(!isBurgerMenuOpen)
  const closeBurgerMenu = () => setNavOpen(false)

  const boxShadowColor = useColorModeValue(
    '0 3px 4px 4px rgb(0 0 0 / 6%)',
    '0 3px 4px 4px rgb(255 255 255 / 6%)'
  )

  return (
    <Flex
      as="header"
      position="fixed"
      align="center"
      justify="center"
      flexDirection="column"
      boxSizing="content-box"
      width="100%"
      height={[undefined, '4rem']}
      py={3}
      bg={useColorModeValue(
        'light.header.background',
        'dark.header.background'
      )}
      color={useColorModeValue('light.header.text', 'dark.header.text')}
      boxShadow={showBoxShadow ? boxShadowColor : undefined}
      zIndex={4}
    >
      <Flex
        alignSelf={['flex-start', 'center']}
        justifyContent={['space-between', 'center']}
        height="inherit"
        width="100%"
        px={4}
      >
        <Box alignSelf="center">
          <Logo />
        </Box>
        <Box display={['flex', 'none']} alignSelf="center">
          <BurgerMenu isOpen={isBurgerMenuOpen} onClick={onToggleBurgerMenu} />
        </Box>
      </Flex>
      <NavBar
        isBurgerMenuOpen={isBurgerMenuOpen}
        onClickNavItem={closeBurgerMenu}
      />
    </Flex>
  )
}

export default memo(Header)
