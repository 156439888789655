import theme from '@/styles/theme'
import { ChakraProvider } from '@chakra-ui/react'
import React, { memo, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
interface IPageWrapper {
  children: React.ReactNode
}

const PageWrapper: React.FC<IPageWrapper> = ({ children }) => {
  const [isBrowser, setIsBrowser] = useState(false)
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsBrowser(true)
    }
  }, [])

  return isBrowser ? (
    <React.StrictMode>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
      >
        <title>Nilly's Burgers</title>
        <meta charSet="utf-8" />
      </Helmet>
      <ChakraProvider theme={theme}>{children}</ChakraProvider>
    </React.StrictMode>
  ) : null
}

export default memo(PageWrapper)
