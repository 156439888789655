import React, { memo } from 'react'
import {
  Box,
  useColorModeValue,
  BoxProps as ChakraBoxProps,
} from '@chakra-ui/react'

interface IBurgerMenu {
  isOpen: boolean
  onClick: React.MouseEventHandler<HTMLDivElement>
}

/* -------------------------------------------------------------------------- */
/*                                   Styles                                   */
/* -------------------------------------------------------------------------- */
const burgerMenuStyles: ChakraBoxProps = {
  width: '1.6rem',
  height: '1.3rem',
  position: 'relative',
  /* This sets the mouse cursor into a pointer when hovering over this element  */
  cursor: 'pointer',

  /* Initialize transform rotation at 0 (not yet rotated) */
  transform: 'rotate(0deg)',

  /* This gives a smoother transitional effect between 
    the burger menu turning into close icon and vice versa */
  transition: '0.5s ease-in-out',
}

const lineStyles: ChakraBoxProps = {
  display: 'block',
  position: 'absolute',
  height: '0.2em',
  width: '100%',
  borderRadius: '0.2em',
  opacity: 1,
  left: 0,
  transform: 'rotate(0deg)',
  transition: '0.25s ease-in-out',
}

const openStyles = {
  line1: {
    top: '0.55em',
    width: 0,
    left: '50%',
  },
  line2: {
    transform: 'rotate(45deg)',
  },
  line3: {
    transform: 'rotate(-45deg)',
  },
  line4: {
    top: '0.55em',
    width: 0,
    left: '50%',
  },
}

/**
 * The Burger Menu (i.e Hamburger Menu) is a "button" with functions to toggle
 * a navigation menu between being collapsed behind the button or displayed on the screen.
 * The "menu" button takes the form of an icon that consists of three parallel horizontal lines
 * (displayed as ≡), suggestive of a list.
 * References:
 * - https://www.justinmind.com/blog/hamburger-menu/
 * - https://blog.hubspot.com/website/hamburger-button
 * @prop isOpen - Whether the burger menu is open (shows menu items) or closed (hides menu items)
 * @prop onClick - Function that is invoked upon clicking on this BurgerMenu component
 * @returns the BurgerMenu component
 */
const BurgerMenu: React.FC<IBurgerMenu> = ({
  onClick,
  isOpen,
}: IBurgerMenu) => {
  return (
    <Box
      className="burger-menu"
      cursor="pointer"
      display="flex"
      alignItems="center"
      onClick={onClick}
      {...burgerMenuStyles}
    >
      <Box
        as="span"
        bg={useColorModeValue('light.header.text', 'dark.header.text')}
        top={0}
        {...lineStyles}
        {...(isOpen ? openStyles.line1 : {})}
      />
      <Box
        as="span"
        bg={useColorModeValue('light.header.text', 'dark.header.text')}
        top="0.55em"
        {...lineStyles}
        {...(isOpen ? openStyles.line2 : {})}
      />
      <Box
        as="span"
        bg={useColorModeValue('light.header.text', 'dark.header.text')}
        top="0.55em"
        {...lineStyles}
        {...(isOpen ? openStyles.line3 : {})}
      />
      <Box
        as="span"
        bg={useColorModeValue('light.header.text', 'dark.header.text')}
        top="1.1em"
        {...lineStyles}
        {...(isOpen ? openStyles.line4 : {})}
      />
    </Box>
  )
}

export default memo(BurgerMenu)
