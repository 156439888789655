import React, { memo } from 'react'
import { navigate } from 'gatsby'
import { Flex, Box, Text } from '@chakra-ui/react'
import { Logo } from '@/components'

interface ILaunchCountdown {
  timeDifferences
}

const timeKeys = ['days', 'hours', 'mins', 'secs']
const LaunchCountdown: React.FC<ILaunchCountdown> = ({ timeDifferences }) => {
  // Redirect to home if pathname is a different page
  if (window.location.pathname !== '/') navigate('/')

  return (
    <Flex justifyContent="center" my={10}>
      <Box mt="5vh">
        <Flex
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          mb="4vh"
        >
          <Flex
            justifyContent="center"
            maxWidth={[250, 300, 350, 400]}
            m={[6, 10]}
          >
            <Logo type={3} isLink={false} />
          </Flex>
          <Text
            textStyle="h2"
            fontSize={['2.2rem', '2.6rem', '3rem', '3.2rem']}
            textTransform="uppercase"
            color="accent.primary"
            whiteSpace="nowrap"
          >
            Coming Soon
          </Text>
        </Flex>

        <Flex columnGap={[6, 7, 8]} justifyContent="space-evenly" mx={10}>
          {timeKeys.map((key) => (
            <Flex
              textStyle="h1"
              fontWeight="light"
              fontSize={['1rem', '1.2rem', '1.4rem', '1.6rem']}
              flexDirection="column"
              alignItems="center"
            >
              <Text fontSize="2em">{timeDifferences[key] || '0'} </Text>
              <Text fontSize="1em" textTransform="uppercase">
                {key}
              </Text>
            </Flex>
          ))}
        </Flex>
      </Box>
    </Flex>
  )
}

export default memo(LaunchCountdown)
