export const darkTheme = {
  header: {
    background: '#000000',
    text: '#FFFFFF',
  },
  footer: {
    background: '#000000',
    text: '#FFFFFF',
    textHover: '#DDDDDD',
  },
  container: {
    background: '#000000',
    translucentBackground: 'rgba(30, 30, 30, 0.8)',
    text: '#FFFFFF',
  },
}
export const lightTheme = {
  header: {
    background: '#FFFFFF',
    text: '#000000',
  },
  footer: {
    background: '#FFFFFF',
    text: '#000000',
    textHover: '#4C4C4C',
  },
  container: {
    background: '#FFFFFF',
    translucentBackground: 'rgba(255, 255, 255, 0.86)',
    headingText: '#231F20',
    text: '#000000',
  },
}

export const colors = {
  primary: {
    main: '#3EAA00', // Also 500
    200: '#78c44d',
    400: '#51b31a',
    500: '#3eaa00',
    600: '#389900',
    800: '#328800',
  },
  grey: {
    main: '#9E9E9E', // Also 500
    200: '#DDDDDD',
    500: '#9E9E9E',
    800: '#4C4C4C',
    900: '#2d2d2d',
  },
  common: {
    black: '#000000',
    white: '#FFFFFF',
  },
  button: {
    background: '#3BAA04',
    text: '#FFFFFF',
  },
  accent: {
    primary: '#F43700',
  },
  alert: {
    primary: '#F43700',
  },
  dark: {
    ...darkTheme,
  },
  light: {
    ...lightTheme,
  },
}
