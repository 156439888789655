export const BUSINESS_HOURS: Record<DayOfWeekAbbr, BusinessHours | null> = {
  Sun: {
    open: '12:00 pm',
    close: '5:00 pm',
  },
  Mon: null, // Null implies "CLOSED"
  Tue: {
    open: '11:00 am',
    close: '8:00 pm',
  },
  Wed: {
    open: '11:00 am',
    close: '9:00 pm',
  },
  Thu: {
    open: '11:00 am',
    close: '9:00 pm',
  },
  Fri: {
    open: '11:00 am',
    close: '9:00 pm',
  },
  Sat: {
    open: '11:00 am',
    close: '9:00 pm',
  },
}

export const BUSINESS_HOURS_ABBR: Record<string, BusinessHours | null> = {
  Mon: null, // Null implies "CLOSED"
  'Tue-Sun': {
    open: '11:00 am',
    close: '8:00 pm',
  },
}
