export function getDaysBetweenDates(d1: Date, d2: Date) {
  return msBetweenDates(d1, d2) / (24 * 60 * 60 * 1000)
}

export function isCurrDate(d1: Date, d2: Date) {
  if (
    d1.getDate() === d2.getDate() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getFullYear() === d2.getFullYear()
  ) {
    return true
  }

  return false
}

export function isFutureDate(d1: Date, d2: Date) {
  if (
    d1.getDate() <= d2.getDate() &&
    d1.getMonth() <= d2.getMonth() &&
    d1.getFullYear() <= d2.getFullYear()
  ) {
    return true
  }

  return false
}

function msBetweenDates(d1: Date, d2: Date) {
  return Math.abs(d2.getTime() - d1.getTime())
}
