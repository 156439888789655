const _second = 1000
const _minute = _second * 60
const _hour = _minute * 60
const _day = _hour * 24

export const getTimeDifferences = (date1: Date, date2: Date) => {
  const distance = date2.getTime() - date1.getTime()

  const days = Math.floor(distance / _day)
  const hours = Math.floor((distance % _day) / _hour)
  const mins = Math.floor((distance % _hour) / _minute)
  const secs = Math.floor((distance % _minute) / _second)

  return {
    days,
    hours,
    mins,
    secs,
    isPastTargetDate: days <= 0 && hours <= 0 && mins <= 0 && secs <= 0,
  }
}
