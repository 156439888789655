import { CLOSED_DATES } from '@/data/closed_dates'
import {
  getDaysBetweenDates,
  isCurrDate,
  isFutureDate,
} from '@/utils/date-utils'
import { useEffect, useState } from 'react'

export const useIsClosed = () => {
  const [isClosed, setIsClosed] = useState(false)
  const [isEarlyClosure, setIsEarlyClosure] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [alertText, setAlertText] = useState('')

  const currDate = new Date()

  useEffect(() => {
    const [isClosedToday, closedDayText, isEarlyClosure] = isClosedDay(currDate)
    const [isClosedWithinAWeek, closedWeekBeforeText] =
      isWeekBeforeClosedDay(currDate)

    if (isClosedWithinAWeek || isClosedToday) {
      let alertText = ''
      if (isClosedWithinAWeek) {
        setIsClosed(false)
        alertText = closedWeekBeforeText
      }
      if (isClosedToday) {
        setIsClosed(true)
        setIsEarlyClosure(isEarlyClosure)
        alertText = closedDayText
      }
      setAlertText(alertText)
      setShowAlert(true)
    } else {
      setIsClosed(false)
      setShowAlert(false)
    }
  }, [])

  return { isClosed, showAlert, date: currDate, alertText, isEarlyClosure }
}

function isWeekBeforeClosedDay(date: Date): [boolean, string] {
  const keys = Object.keys(CLOSED_DATES)

  for (let i = 0; i < keys.length; i++) {
    const k = keys[i]
    const closedDate = CLOSED_DATES[k].date
    if (isFutureDate(date, closedDate)) {
      const days = getDaysBetweenDates(date, closedDate)
      if (days <= 7 && days >= 0.9) {
        return [true, CLOSED_DATES[k].weekBeforeText]
      }
    }
  }

  return [false, '']
}

function isClosedDay(date: Date): [boolean, string, boolean] {
  const keys = Object.keys(CLOSED_DATES)

  for (let i = 0; i < keys.length; i++) {
    const k = keys[i]
    const closedDate = CLOSED_DATES[k].date
    if (isFutureDate(date, closedDate)) {
      if (isCurrDate(date, closedDate)) {
        return [true, CLOSED_DATES[k].text, CLOSED_DATES[k].isEarlyClosure]
      }
    }
  }

  return [false, '', false]
}
