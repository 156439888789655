import { colors } from '@/styles/theme/colors'
import {
  AlertDialog as ChakraAlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Text,
} from '@chakra-ui/react'
import { FC, useRef } from 'react'
import Confetti from 'react-confetti'
import useWindowSize from 'react-use/lib/useWindowSize'

interface AlertDialogProps {
  header: string
  isOpen: boolean
  onClose: () => void
  celebrate?: boolean
}

export const AlertDialog: FC<AlertDialogProps> = ({
  header,
  celebrate,
  children,
  isOpen,
  onClose,
}) => {
  const cancelRef = useRef()
  const { width, height } = useWindowSize()

  return (
    <>
      {isOpen && celebrate && (
        <Confetti
          width={width}
          height={height}
          style={{ zIndex: 45 }}
          gravity={0.02}
          recycle={true}
          numberOfPieces={200}
          tweenDuration={500}
          colors={[colors.primary.main]}
        />
      )}
      <ChakraAlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        motionPreset="slideInBottom"
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent
          backgroundColor="common.white"
          padding={0}
          paddingBottom="16px"
          width="xl"
        >
          <AlertDialogHeader
            fontSize="lg"
            fontWeight="bold"
            color="common.black"
            display="flex"
            justifyContent="center"
          >
            <Text textStyle="h3" fontSize={'1.4em'} fontWeight="normal">
              {header}
            </Text>
          </AlertDialogHeader>
          <AlertDialogCloseButton onClick={onClose} color="common.black" />

          <AlertDialogBody paddingTop="0">{children}</AlertDialogBody>
        </AlertDialogContent>
      </ChakraAlertDialog>
    </>
  )
}
