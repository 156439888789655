import React, { memo } from 'react'
import {
  Flex,
  Grid,
  Box,
  Text,
  Link,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react'
import { nanoid } from 'nanoid'
import { Logo, BusinessHours } from '@/components'
import { BUSINESS_INFO, COPYRIGHT } from '@/data/business-info'

interface IFooter {
  isHidden?: boolean
}

/**
 * The Footer is the section of content at the very bottom of a web page
 * UX Reference: https://blog.hubspot.com/website/website-footer
 * @returns the Footer component
 */
const Footer: React.FC<IFooter> = ({ isHidden }: IFooter) => {
  const textHoverColor = useColorModeValue(
    'light.footer.textHover',
    'dark.footer.textHover'
  )
  const textHoverStyles = {
    _hover: {
      color: textHoverColor,
    },
    _focus: {
      color: textHoverColor,
    },
    _active: {
      color: textHoverColor,
    },
  }

  return (
    <Flex
      id="footer"
      className="footer"
      as="footer"
      flexDirection="column"
      alignItems="center"
      alignContent="center"
      justifyContent="center"
      width="100%"
      minHeight="25rem"
      py={10}
      bg={useColorModeValue(
        'light.footer.background',
        'dark.footer.background'
      )}
      color={useColorModeValue('light.footer.text', 'dark.footer.text')}
      display={isHidden ? 'none' : undefined}
    >
      <Flex
        flexDirection={['column', 'column', 'row']}
        justifyContent={['center', 'center', 'space-evenly']}
        alignItems={['center', 'center', 'flex-start']}
        width="fit-content"
        maxWidth={['fit-content', 'fit-content', '50rem']}
        height="max-content"
        mx={20}
        columnGap={10}
        rowGap={10}
      >
        <Box minWidth={200} mr={{ lg: 4 }}>
          <Logo {...textHoverStyles} type={2} />
        </Box>
        <Flex
          justifyContent="center"
          alignItems={['center', 'flex-start']}
          ml={[5, 5, 5, 0]}
        >
          <Grid gridGap={2}>
            {Object.keys(BUSINESS_INFO).map((key) => {
              const { value, href, icon, isLink } = BUSINESS_INFO[key]
              return (
                <Link
                  key={nanoid()}
                  href={href}
                  target={isLink ? '_blank' : undefined}
                >
                  <Flex
                    alignItems="center"
                    justifyContent="flex-start"
                    width="100%"
                    {...textHoverStyles}
                  >
                    <Icon as={icon} />
                    <Text ml={4} maxWidth="12rem">
                      {value}
                    </Text>
                  </Flex>
                </Link>
              )
            })}
          </Grid>
        </Flex>
        <Flex justifyContent="center" alignItems="flex-start">
          <BusinessHours />
        </Flex>
      </Flex>
      <Box mt={[10, 10, 6]} textAlign="center" fontSize={['sm', 'md']}>
        <Text fontWeight="light">{COPYRIGHT}</Text>
      </Box>
    </Flex>
  )
}

export default memo(Footer)
