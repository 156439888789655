import AndroidChromeIcon192 from '@/assets/favicons/android-chrome-192x192.png'
import AndroidChromeIcon512 from '@/assets/favicons/android-chrome-512x512.png'
import AppleTouchIcon from '@/assets/favicons/apple-touch-icon.png'
import Favicon16 from '@/assets/favicons/favicon-16x16.png'
import Favicon32 from '@/assets/favicons/favicon-32x32.png'
import { Header, PageWrapper } from '@/components'
import { ANNIVERSARY_DAY } from '@/data/anniversary-specials'
import { getDaysBetweenDates } from '@/utils/date-utils'
import { useIsClosed } from '@/utils/hooks/useIsClosed'
import useLaunchDayTimer from '@/utils/hooks/useLaunchDayTimer'
import { Box, Flex, Skeleton, useDisclosure } from '@chakra-ui/react'
import loadable from '@loadable/component'
import { StaticImage } from 'gatsby-plugin-image'
import React, { memo, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import AlertBanner from '../Alert'
import AlertDialog from '../AlertDialog'
import LaunchCountdown from './LaunchCountdown'

const Footer = loadable(() => import('../Footer'))

interface ILayout {
  children: React.ReactNode
  title: string
  metaDescription?: string
  hideHeaderBoxShadow?: boolean
  hideFooter?: boolean
  isComingSoon?: boolean
  isDisabled?: boolean
}

/**
 * The Layout contains the basic structure of the website.
 * This Layout contains a Header, main (i.e the main page content), and the Footer
 * UX Reference: https://developer.mozilla.org/en-US/docs/Learn/HTML/Introduction_to_HTML/Document_and_website_structure
 * @returns the Layout component
 */
const Layout: React.FC<ILayout> = ({
  children,
  title,
  metaDescription,
  hideHeaderBoxShadow,
  hideFooter,
  ...props
}: ILayout) => {
  const [showHeaderBoxShadow, setShowHeaderBoxShadow] = useState(false)
  const timeTilLaunch = useLaunchDayTimer()
  const { showAlert, alertText, isClosed, isEarlyClosure } = useIsClosed()

  const isDayAfterAnniversary =
    getDaysBetweenDates(new Date(), ANNIVERSARY_DAY) === 1
  const { isOpen, onOpen, onClose } = useDisclosure()

  const getAlertText = () => {
    return alertText
  }

  const getAlertStatus = (): 'error' | 'info' | 'warning' | 'success' => {
    if (isClosed && !isEarlyClosure) return 'error'

    return 'warning'
  }

  const ShowAlertBanner = () => {
    if (isDayAfterAnniversary) {
      return (
        <AlertBanner
          text={getAlertText()}
          status={getAlertStatus()}
          actionText="See more details"
          actionFn={() => {
            onOpen()
          }}
        />
      )
    }

    if (showAlert) {
      return <AlertBanner text={getAlertText()} status={getAlertStatus()} />
    }

    return null
  }

  useEffect(() => {
    const showBoxShadow = () => {
      setShowHeaderBoxShadow(window.scrollY > 40)
    }
    if (!hideHeaderBoxShadow) {
      window.addEventListener('scroll', showBoxShadow)
    }

    const anniversaryPopupDisplayed = window.localStorage.getItem(
      'anniversaryPopupDisplayed'
    )
    if (!anniversaryPopupDisplayed) {
      setTimeout(() => {
        onOpen()
      }, 500)
      window.localStorage.setItem('anniversaryPopupDisplayed', 'true')
    }
    return () => {
      window.removeEventListener('scroll', showBoxShadow)
    }
  }, [])

  const layout = (
    <>
      <Helmet>
        <title> {title} </title>
        <link
          rel="android-chrome-large"
          sizes="512x512"
          href={AndroidChromeIcon512}
        />
        <link
          rel="android-chrome"
          sizes="180x180"
          href={AndroidChromeIcon192}
        />
        <link rel="apple-touch-icon" sizes="180x180" href={AppleTouchIcon} />
        <link rel="icon" type="image/png" sizes="32x32" href={Favicon32} />
        <link rel="icon" type="image/png" sizes="16x16" href={Favicon16} />
        <link rel="icon" type="image/ico" sizes="48x48" href="/favicon.ico" />
        <meta charSet="utf-8" />
        <meta name="description" content={metaDescription} />
      </Helmet>
      <Flex direction="column" align="center" m="0 auto" {...props}>
        <Header showBoxShadow={showHeaderBoxShadow} />
        {ShowAlertBanner()}
        <Box
          as="main"
          className="page-content"
          minWidth="100%"
          height="100%"
          minHeight={['calc(100vh - 3.5rem)', 'calc(100vh - 5.5rem)']}
          mt={['3.5rem', '5.5rem']}
        >
          {children}
          {isDayAfterAnniversary && (
            <AlertDialog
              header={'CLOSED TODAY (01/08/2023)'}
              isOpen={isOpen}
              onClose={onClose}
            >
              <StaticImage
                src="../../assets/images/misc/nillys_sudden_close.jpg"
                alt={`Nilly's staff closed today message`}
                style={{ width: '100%', height: '100%' }}
                imgStyle={{ width: 'auto', maxHeight: '100%' }}
              />
            </AlertDialog>
          )}
        </Box>
        <Footer
          isHidden={hideFooter}
          fallback={<Skeleton minHeight="25rem" />}
        />
      </Flex>
    </>
  )

  return (
    <PageWrapper>
      {!timeTilLaunch.isPastTargetDate && !global.nillysDev ? (
        <LaunchCountdown timeDifferences={timeTilLaunch} />
      ) : (
        layout
      )}
    </PageWrapper>
  )
}

export default memo(Layout)
