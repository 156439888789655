import { WarningTwoIcon } from '@chakra-ui/icons'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { FC } from 'react'

type AlertBannerProps = {
  text: string
  status: 'error' | 'info' | 'warning' | 'success'
  actionText?: string
  actionFn?: () => void
  ref?: React.ForwardedRef<any>
}

export const AlertBanner: FC<AlertBannerProps> = ({
  text,
  status = 'error',
  actionText,
  actionFn,
}) => {
  return (
    <Alert
      status={status}
      style={{
        textAlign: 'center',
        position: 'sticky',
        zIndex: 3,
      }}
      top={['3.5rem', '5.5rem']}
      variant={useColorModeValue('subtle', 'solid')}
    >
      {status === 'error' && (
        <AlertIcon color="#C41E3A" style={{ marginRight: '8px' }} />
      )}
      {status === 'warning' && (
        <WarningTwoIcon color="#C41E3A" style={{ marginRight: '8px' }} />
      )}

      {status === 'success' && <Text marginRight="8px">🎉</Text>}
      <AlertTitle>
        <Text display="inline">{text} </Text>

        <Text
          display="inline"
          onClick={actionFn}
          fontWeight="bold"
          textDecoration="underline"
          cursor="pointer"
        >
          {actionText}
        </Text>
      </AlertTitle>

      {status === 'success' && <Text marginRight="8px">🎉</Text>}
    </Alert>
  )
}
