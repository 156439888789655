import React, { memo } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { Link as ChakraLink } from '@chakra-ui/react'
import { Box, Text, useColorModeValue } from '@chakra-ui/react'

interface INavItem {
  path?: string
  subtitle?: string
  isActive?: boolean
  isLast?: boolean
  isDisabled?: boolean
  isOuterPath?: boolean
  isComingSoon?: boolean
  onClick: () => void
  children?: React.ReactNode
}

/**
 * The NavItem is used by the NavBar. Each NavItem is a link that routes to a page.
 * @returns the NavItem component
 */
export const NavItem: React.FC<INavItem> = ({
  children,
  path = '/',
  subtitle,
  isActive,
  isLast,
  isDisabled,
  isOuterPath,
  isComingSoon,
  onClick,
  ...props
}: INavItem) => {
  let className = 'nav-item'
  if (isComingSoon) className += ' coming-soon'

  let color = useColorModeValue('light.header.text', 'dark.header.text')
  if (isActive) color = useColorModeValue('primary.main', 'primary.400')
  if (isDisabled) color = 'grey.main'

  const Link: any = isOuterPath ? ChakraLink : GatsbyLink
  const linkProps = isOuterPath
    ? { href: path, target: '_blank' }
    : { to: path }

  return (
    <Link {...linkProps}>
      <Box
        className={className}
        title={isComingSoon ? 'Coming Soon!' : undefined}
        transition="0.3s ease-in"
        textDecoration="underline"
        textDecorationColor="transparent"
        textUnderlineOffset="3px"
        onClick={onClick}
        _hover={{ textDecorationColor: color }}
        _active={{ textDecorationColor: color }}
        pointerEvents={isDisabled ? 'none' : 'auto'}
        cursor={isDisabled ? 'default' : 'pointer'}
      >
        <Text
          textStyle="nav"
          display="block"
          color={color}
          textTransform="uppercase"
          {...props}
        >
          {children}
        </Text>
      </Box>
    </Link>
  )
}

export default memo(NavItem)
