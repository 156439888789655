type ClosedDate = {
  date: Date
  text: string
  weekBeforeText: string
  isEarlyClosure?: boolean
}

const DAYS_OF_WEEK = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

const currYear = new Date().getFullYear()
// Months are zero-based: Jan = 0 and Dec = 11
export const CLOSED_DATES: Record<string, ClosedDate> = {
  halloween: {
    date: createClosedDate(9, 31),
    text: defaultEarlyClosureText(`Halloween`, 'Happy Halloween!'),
    weekBeforeText: defaultWeekBeforeText(`10/31/${currYear}`, `Halloween`),
    isEarlyClosure: true,
  },
  thanksgiving: {
    date: createClosedDate(10, 23),
    text: defaultText(`Thanksgiving`, 'have a Happy Thanksgiving!'),
    weekBeforeText: defaultWeekBeforeText(
      `11/24/${currYear}`,
      `Thanksgiving Day`
    ),
  },
  christmasEve: {
    date: createClosedDate(11, 24),
    text: defaultEarlyClosureText(`Christmas Eve`, 'have a Merry Christmas!'),
    weekBeforeText: earlyClosureAndClosedNextDayText({
      earlyClosedDate: `12/24/${currYear}`,
      closedDate: `12/25/${currYear}`,
      earlyClosedReason: 'Christmas Eve',
      closedReason: 'Christmas Day',
    }),
    isEarlyClosure: true,
  },
  christmas: {
    date: createClosedDate(11, 25),
    text: defaultText(
      `Christmas Day (12/25/${currYear})`,
      'have a Merry Christmas!'
    ),
    weekBeforeText: defaultWeekBeforeText(`12/25/${currYear}`, `Christmas Day`),
  },
  newYearsEve: {
    date: createClosedDate(11, 31),
    text: defaultEarlyClosureText(`New Years Eve`, 'have a Happy New Years!'),
    weekBeforeText: earlyClosureAndClosedNextDayText({
      earlyClosedDate: `12/31/${currYear}`,
      closedDate: `01/01/${currYear}`,
      earlyClosedReason: 'New Years Eve',
      closedReason: 'New Years Day',
    }),
    isEarlyClosure: true,
  },
  newYears: {
    date: createClosedDate(0, 1),
    text: defaultText(
      `New Years Day (01/01/${currYear})`,
      'have a Happy New Years!'
    ),
    weekBeforeText: defaultWeekBeforeText(`01/01/${currYear}`, `New Years Day`),
  },
  julyFourth: {
    date: createClosedDate(6, 4),
    text: defaultEarlyClosureText(
      `July 4th (07/04/${currYear})`,
      'have a happy 4th of July!'
    ),
    weekBeforeText: defaultWeekBeforeText(`07/04/${currYear}`, `July 4th`),
    isEarlyClosure: true,
  },
  // afterAnniversary: {
  //   date: createClosedDate(0, 8),
  //   // Our tiny shop cranked out an insane amount of burgers the whole day
  //   text: "Closed today! We cranked out an insane amount of burgers the whole day. Thank you for understanding! We'll be back Tuesday (01/10/2023).",
  //   weekBeforeText: '',
  // },
  // babyShower: {
  //   date: createClosedDate(7, 6),
  //   text: "Closed today. Thank you for understanding! We'll be back Tuesday (08/08/2023).",
  //   weekBeforeText: defaultWeekBeforeText(
  //     `08/06/${currYear}`,
  //     '',
  //     DAYS_OF_WEEK[createClosedDate(7, 6).getDay()]
  //   ),
  // },
}

function createClosedDate(month: number, day: number) {
  const d = new Date()
  d.setMonth(month, day)
  return d
}

function defaultText(str: string, strSuffix?: string) {
  return `We are closed today for ${str}. Thank you for understanding and ${strSuffix}`
}

function defaultEarlyClosureText(str: string, strSuffix?: string) {
  return `We are closing earlier today at 5:00pm for ${str}. Thank you for understanding and ${strSuffix}`
}

function earlyClosureAndClosedNextDayText({
  earlyClosedDate,
  closedDate,
  earlyClosedReason,
  closedReason,
}) {
  return `We will be closed early on ${earlyClosedDate} and closed on ${closedDate} for ${earlyClosedReason} and ${closedReason} respectively. Please check our socials for adjusted business hours!`
}

function defaultWeekBeforeText(
  closedDate: string,
  str: string = '',
  dayOfWeek: string = '',
  isEarlyClosure = false
) {
  let prefixStr = ''

  if (!prefixStr) {
    prefixStr = dayOfWeek
      ? `We will be closed on ${dayOfWeek} (${closedDate})`
      : `We will be closed on ${closedDate}`
  }

  if (str) {
    prefixStr += ` for ${str}`
  }
  return `${prefixStr}. Please check our socials for adjusted business hours!`
}
