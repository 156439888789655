import React from 'react'
import { Grid, Text, GridProps as ChakraGridProps } from '@chakra-ui/react'
import { BUSINESS_HOURS } from '@/data/business-hours'
import { nanoid } from 'nanoid'

interface IBusinessHours extends ChakraGridProps {}

const BusinessHours: React.FC<IBusinessHours> = (props) => {
  const DayOfWeekAbbr = Object.keys(BUSINESS_HOURS)
  return (
    <Grid gridGap={1} {...props}>
      {DayOfWeekAbbr.map((day) => (
        <Grid key={nanoid()} gridTemplateColumns="3em 1fr" gridGap={2}>
          <Text fontSize="1em" textTransform="uppercase">
            {day}
          </Text>
          <Text fontSize="1em" whiteSpace={'nowrap'} fontWeight="light">
            {BUSINESS_HOURS[day]
              ? `${BUSINESS_HOURS[day].open} — ${BUSINESS_HOURS[day].close}`
              : 'CLOSED'}
          </Text>
        </Grid>
      ))}
    </Grid>
  )
}

export default BusinessHours
