const JAN7_11AM = new Date(2023, 0, 7, 11, 0, 0)
const JAN7_4PM = new Date(2023, 0, 7, 16, 0, 0)
const JAN7_9PM = new Date(2023, 0, 7, 21, 0, 0)

export const DAY_BEFORE_ANNIVERSARY_DAY = new Date(2023, 0, 6, 0, 0, 0)
export const ANNIVERSARY_DAY = new Date(2023, 0, 7, 0, 0, 0)
export const ANNIVERSARY_ITEMS = [
  {
    name: 'Patty Melt',
    timeRangeText: '11AM - 4PM',
    startTime: JAN7_11AM,
    endTime: JAN7_4PM,
  },
  {
    name: "Big Rig's Bacon BBQ Burger",
    timeRangeText: '4PM - 9PM',
    startTime: JAN7_4PM,
    endTime: JAN7_9PM,
  },
  {
    name: 'Pastrami Burger',
    timeRangeText: '4PM - 9PM',
    startTime: JAN7_4PM,
    endTime: JAN7_9PM,
  },
]
