import { extendTheme, ThemeConfig } from '@chakra-ui/react'
import type { GlobalStyleProps, Styles } from '@chakra-ui/theme-tools'
import { mode } from '@chakra-ui/theme-tools'
import { colors } from './colors'
import { fonts, textStyles } from './fonts'

// setup light/dark mode global defaults
const styles: Styles = {
  global: (props) => ({
    body: {
      color: mode('light.container.text', 'dark.container.text')(props),
      bg: mode(
        'light.container.background',
        'dark.container.background'
      )(props),
    },
  }),
}

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: true,
}

const customTheme = extendTheme({ config, styles, colors, fonts, textStyles })

export default customTheme
