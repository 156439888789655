import React, { memo } from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import {
  Box,
  BoxProps as ChakraBoxProps,
  TypographyProps as ChakraTypographyProps,
} from '@chakra-ui/react'

interface ILogo extends ChakraBoxProps, ChakraTypographyProps {
  type?: 1 | 2 | 3 // 1 by default
  isLink?: boolean // True by default
}

const getLogo = (type: number) => {
  switch (type) {
    case 2:
      return (
        <StaticImage
          src="../../assets/images/logo/nillys_bfd_logo.png"
          alt={`Nilly's Burgers Fries & Drinks logo`}
          style={{ maxHeight: 88 }}
          imgStyle={{ width: 'auto', maxHeight: 88 }}
        />
      )
    case 3:
      return (
        <StaticImage
          src="../../assets/images/logo/nillys_nbs_logo.png"
          alt="Nilly's Neighborhood Burger Shop logo"
          style={{ maxHeight: 240 }}
          imgStyle={{ height: 'auto', maxHeight: 240 }}
        />
      )
    default:
      return (
        <StaticImage
          src="../../assets/images/logo/nillys_logo.png"
          alt="Nilly's logo"
          style={{ maxHeight: 32 }}
          imgStyle={{ width: 'auto', maxHeight: 32 }}
          placeholder="none"
          // backgroundColor="transparent"
        />
      )
  }
}
/**
 * The Logo is Nilly's main business logo.
 * Also functions as a link that navigates to the home page when clicked on
 * @returns the Logo component
 */
const Logo: React.FC<ILogo> = ({
  type,
  isLink = true,
  className,
  ...props
}: ILogo) => {
  let logoClassName = 'nillys-logo'

  if (type === 2) logoClassName += ' bfd'
  else if (type === 3) logoClassName += ' nbs'
  else logoClassName += ' default'

  if (className) logoClassName += ` ${className}`

  // let logo = NillysLogo
  // if (type === 2) logo = NillysBFDLogo
  // // if (type === 3) logo = NillysNBSLogo

  // let logoHeight: string | string[] = ['1.8em', '2em']
  // if (type === 2) logoHeight = '3em'
  // if (type === 3) logoHeight = undefined

  const icon = (
    <Box className={logoClassName} mt={0.5} {...props}>
      {getLogo(type)}
    </Box>
  )

  return isLink ? <Link to="/">{icon}</Link> : icon
}

export default memo(Logo)
