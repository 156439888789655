type Route = {
  name: string // Required. The route name (i.e "About")
  subtitle?: string
  path: string // Required. The path (i.e "/about")
  isOuterPath?: boolean // '?:' refers to optional property
  isComingSoon?: boolean
  isDisabled?: boolean
  isFooterHidden?: boolean
}

const routes: Record<string, Route> = {
  home: {
    name: 'Home',
    path: '/',
  },
  about: {
    name: 'About',
    path: '/about',
  },
  order: {
    name: 'Order',
    path: 'https://www.toasttab.com/nillysburgers/v3',
    isOuterPath: true,
  },
  menu: {
    name: 'Menu',
    path: '/menu',
    isFooterHidden: true,
  },
  contact: {
    name: 'Contact',
    path: '/contact',
  },
}

export default routes
