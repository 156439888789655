import { NavItem } from '@/components'
import routes from '@/routes'
import { getRouteName } from '@/utils/route-utils'
import { Flex, Stack } from '@chakra-ui/react'
import { nanoid } from 'nanoid'
import React, { memo } from 'react'

interface INavBar {
  isBurgerMenuOpen?: boolean
  onClickNavItem: () => void
}

/**
 * The NavBar is the navigational menu bar that contains the links to different pages on the website
 * @prop isBurgerMenuOpen: This is only relevant for Mobile view.
 *        Value for whether the BurgerMenu is open (shows NavBar) or closed (hides NavBar)
 * @prop onClickNavItem: Function that is invoked upon clicking on the NavItem
 * @returns the NavBar component
 */
const NavBar: React.FC<INavBar> = ({
  isBurgerMenuOpen,
  onClickNavItem,
  ...props
}: INavBar) => {
  const routeName = getRouteName((window && window.location.pathname) || '')
  return (
    <Flex
      as="nav"
      id="navbar"
      align={['flex-start', 'center']}
      justify="center"
      wrap="wrap"
      transition="0.2s ease-in"
      height={[isBurgerMenuOpen ? '100vh' : '0', 'auto']}
      mt={[isBurgerMenuOpen ? '15vh' : '0', 2]}
      {...props}
    >
      <Stack
        spacing={8}
        align="center"
        justify={['center', 'space-between', 'flex-end']}
        direction={['column', 'row']}
        transition={[isBurgerMenuOpen ? '0.2s ease-out' : 'none', 'none']}
        textAlign={['center', 'left']}
        display={[isBurgerMenuOpen ? 'flex' : 'none', 'flex']}
        opacity={[isBurgerMenuOpen ? 1 : 0, 1]}
        {...props}
      >
        {Object.keys(routes).map((key) => {
          const route = routes[key]
          return (
            <NavItem
              path={route.path}
              isActive={
                !route.isOuterPath && routeName === getRouteName(route.path)
              }
              isOuterPath={route.isOuterPath}
              isComingSoon={route.isComingSoon}
              isDisabled={route.isDisabled}
              onClick={onClickNavItem}
              key={nanoid()}
            >
              {route.name}
            </NavItem>
          )
        })}
      </Stack>
    </Flex>
  )
}

export default memo(NavBar)
