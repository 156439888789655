import {
  FaArrowRight,
  FaEnvelope,
  FaInstagram,
  FaMapMarkerAlt,
  FaPhone,
  FaTwitter,
} from 'react-icons/fa'
import { ADDRESS, ADDRESS_MAP_LINK } from './business-address'

export const PHONE = '(562) 278-2309'
export const EMAIL = 'nillysbnf@gmail.com'
export const INSTAGRAM = 'nillysburgershop'
export const TWITTER = 'NILLYSBURGERS'
export const ORDER = 'Order Online'
export const COPYRIGHT = `© 2022 Nilly's Burgers. All rights reserved.`

export const BUSINESS_INFO = {
  order: {
    value: ORDER,
    href: `https://www.toasttab.com/nillysburgers/v3`,
    isLink: true,
    icon: FaArrowRight,
  },
  phone: {
    value: PHONE,
    href: `tel:${PHONE}`,
    icon: FaPhone,
  },
  email: {
    value: EMAIL,
    href: `mailto:${EMAIL}`,
    icon: FaEnvelope,
  },
  instagram: {
    value: `@${INSTAGRAM}`,
    href: `https://instagram.com/${INSTAGRAM}`,
    isLink: true,
    icon: FaInstagram,
  },
  twitter: {
    value: `@${TWITTER}`,
    href: `https://twitter.com/${TWITTER}`,
    isLink: true,
    icon: FaTwitter,
  },
  address: {
    value: ADDRESS,
    href: ADDRESS_MAP_LINK,
    isLink: true,
    icon: FaMapMarkerAlt,
  },
}
